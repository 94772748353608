<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<h5>报告下载</h5>
				<div class="formgroup-inline">
					<span class="p-float-label mr-3">
						<Calendar id="range" v-model="timeRange" selectionMode="range" manualInput="true"
							showButtonBar="true" />
						<label for="range">选择时间范围</label>
					</span>
					<Button label="查询" class="mr-2" @click="getLogList"></Button>
					<Button label="导出" @click="exportLogs"></Button>
				</div>
			</div>
		</div>

		<div class="col-12">
			<div class="card">
				<DataTable :value="logList" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id"
					:rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="loading1"
					:filters="filters1" responsiveLayout="scroll"
					:globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">
					<template #empty>
						No customers found.
					</template>
					<template #loading>
						Loading customers data. Please wait.
					</template>

					<Column field="name" header="ID" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.id }}
						</template>
					</Column>

					<Column field="name" header="报告时间" style="min-width:15rem">
						<template #body="{ data }">
							{{ data.created_at }}
						</template>
					</Column>

					<Column field="name" header="报告人" style="min-width:15rem">
						<template #body="{ data }">
							{{ data.user_name }}
						</template>
					</Column>

					<Column field="name" header="内容摘要" style="min-width:12rem">
						<template #body="{ data }">
							{{ data.content }}
						</template>
					</Column>
					<Column field="name" header="下载" style="min-width:12rem">
						<template #body="{ data }">
							<Button label="下载" class="p-button-danger p-button-text mr-1" @click="download(data.id)" />
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Dialog header="正在导出" v-model:visible="displayLoading" :style="{ width: '30vw' }" :modal="true">
		<p class="m-0">系统正在导出日志列表，请稍候</p>
	</Dialog>

</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import CustomerService from "../service/CustomerService";
import ProductService from '../service/ProductService';
import { xlsx_exprot } from "@/utils/xlsx-export";
export default {
	data() {
		return {
			customer1: null,
			customer2: null,
			customer3: null,
			filters1: null,
			filters2: {},
			loading1: true,
			loading2: true,
			idFrozen: false,
			products: null,
			expandedRows: [],
			statuses: [
				'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
			],
			representatives: [
				{ name: "Amy Elsner", image: 'amyelsner.png' },
				{ name: "Anna Fali", image: 'annafali.png' },
				{ name: "Asiya Javayant", image: 'asiyajavayant.png' },
				{ name: "Bernardo Dominic", image: 'bernardodominic.png' },
				{ name: "Elwin Sharvill", image: 'elwinsharvill.png' },
				{ name: "Ioni Bowcher", image: 'ionibowcher.png' },
				{ name: "Ivan Magalhaes", image: 'ivanmagalhaes.png' },
				{ name: "Onyama Limba", image: 'onyamalimba.png' },
				{ name: "Stephen Shaw", image: 'stephenshaw.png' },
				{ name: "XuXue Feng", image: 'xuxuefeng.png' }
			],
			breadcrumbHome: { icon: 'pi pi-home', label: ' James Butt', to: '#' },
			breadcrumbItems: [
				{ label: '' }
			],

			//-------------------------------------------
			totalCount: 0, // 数据总数
			page_index: 1, // 当前页数
			page_size: 10, // 每页条数

			// //   开始 时间
			// starttime: '',
			// //   结束时间
			// endtime: '',
			// 列表
			logList: null,
			timeRange: null,
			displayLoading: false,
			//-------------------------------------------
		}
	},
	customerService: null,
	productService: null,
	created() {
		this.customerService = new CustomerService();
		this.productService = new ProductService();
		this.initFilters1();
	},
	mounted() {
		this.productService.getProductsWithOrdersSmall().then(data => this.products = data);
		this.customerService.getCustomersLarge().then(data => {
			this.customer1 = data;
			this.loading1 = false;
			this.customer1.forEach(customer => customer.date = new Date(customer.date));
		});
		this.customerService.getCustomersLarge().then(data => this.customer2 = data);
		this.customerService.getCustomersMedium().then(data => this.customer3 = data);
		this.loading2 = false;

		this.getLogList();
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
				'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
				'representative': { value: null, matchMode: FilterMatchMode.IN },
				'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
				'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
				'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
				'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
			}
		},

		download(idx){
			console.log(idx)
		},

		getLogList() {

			var time = null;
			var times = null;

			if (this.timeRange != null) {
				if (this.timeRange[0] != null && this.timeRange[0] != '') {
					let y = new Date(this.timeRange[0]).getFullYear()
					let m = new Date(this.timeRange[0]).getMonth() + 1
					let d = new Date(this.timeRange[0]).getDate()
					let h = new Date(this.timeRange[0]).getHours()
					let min = new Date(this.timeRange[0]).getMinutes()
					let s = new Date(this.timeRange[0]).getSeconds()
					time = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}:${s < 10 ? '0' + s : s}`
				}
				if (this.timeRange[1] != null && this.timeRange[1] != '') {
					let y = new Date(this.timeRange[1]).getFullYear()
					let m = new Date(this.timeRange[1]).getMonth() + 1
					let d = new Date(this.timeRange[1]).getDate()
					let h = new Date(this.timeRange[1]).getHours()
					let min = new Date(this.timeRange[1]).getMinutes()
					let s = new Date(this.timeRange[1]).getSeconds()
					times = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}:${s < 10 ? '0' + s : s}`
				}
			}

			this.$http('/api/admin/log/index', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				time: time == null ? '' : time,
				times: times == null ? '' : times,
				page: this.page_index
			}).then(res => {
				if (res.code == 200) {
					this.logList = res.data.data
					this.totalCount = res.data.total
					this.page_size = res.data.per_page
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		exportLogs() {
			let time, times
			if (this.timeRange[0] != null && this.timeRange[0] != '') {
				let y = new Date(this.timeRange[0]).getFullYear()
				let m = new Date(this.timeRange[0]).getMonth() + 1
				let d = new Date(this.timeRange[0]).getDate()
				let h = new Date(this.timeRange[0]).getHours()
				let min = new Date(this.timeRange[0]).getMinutes()
				let s = new Date(this.timeRange[0]).getSeconds()
				time = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}:${s < 10 ? '0' + s : s}`
			}
			if (this.timeRange[1] != null && this.timeRange[1] != '') {
				let y = new Date(this.timeRange[1]).getFullYear()
				let m = new Date(this.timeRange[1]).getMonth() + 1
				let d = new Date(this.timeRange[1]).getDate()
				let h = new Date(this.timeRange[1]).getHours()
				let min = new Date(this.timeRange[1]).getMinutes()
				let s = new Date(this.timeRange[1]).getSeconds()
				times = `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d} ${h < 10 ? '0' + h : h}:${min < 10 ? '0' + min : min}:${s < 10 ? '0' + s : s}`
			}
			this.displayLoading = true;

			this.$http('/api/admin/log/indexx', {
				enterprise_id: JSON.parse(window.localStorage.getItem('userinfo')).type == 'ad' ? '' : JSON.parse(window.localStorage.getItem('userinfo')).enterprise_id,
				time: this.timeRange[0] == '' || this.timeRange[0] == null ? '' : time,
				times: this.timeRange[1] == '' || this.timeRange[1] == null ? '' : times,
			})
				.then((res) => {
					const arrs = []
					for (const item of res.data.data) {
						const arr = [
							item.id,
							item.created_at,
							item.user_name,
							item.content,
						]
						arrs.push(arr)
					}
					xlsx_exprot(
						[
							'ID',
							'操作时间',
							'操作人',
							'操作事项',
						],
						arrs,
						'操作日志'
					)

					this.displayLoading = false;
				})
				.catch((err) => {
					this.$toast.add({ severity: 'error', summary: err });
				})
		},


		onRowSelect(event) {
			this.$toast.add({ severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		onRowUnselect(event) {
			this.$toast.add({ severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.name, life: 3000 });
		},
		clearFilter1() {
			this.initFilters1();
		},
		expandAll() {
			this.expandedRows = this.products.filter(p => p.id);
		},
		collapseAll() {
			this.expandedRows = null;
		},
		badgeColor(value) {
			return value > 40 ? 'success' : 'warning';
		},
		removeCompany(id) {
			console.print('remove:' + id);
		},
		editCompany(id) {
			console.print('edit ' + id);
		},
		formatCurrency(value) {
			return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		},
		formatDate(value) {
			return value.toLocaleDateString('en-US', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			});
		},
		calculateCustomerTotal(name) {
			let total = 0;
			if (this.customer3) {
				for (let customer of this.customer3) {
					if (customer.representative.name === name) {
						total++;
					}
				}
			}

			return total;
		}
	}
}
</script>