export function xlsx_exprot(th, list, filename) {
	return import('@/vendor/Export2Excel').then(excel => {
		const tHeader = th// ['Id', 'Title', 'Author', 'Readings', 'Date']
		const data = list
		excel.export_json_to_excel({
			header: tHeader, // 表头 必填
			data, // 具体数据 必填
			filename: filename, // 非必填
			autoWidth: true, // 非必填
			bookType: 'xlsx' // 非必填
		})
	})
}
